import { Component, Input, OnInit } from '@angular/core';
import { finalize, map, Observable, tap } from 'rxjs';
import { IQuestion } from '../shared/interfaces/IQuestion';
import { TenantService } from '../shared/services/tenant.service';
import { FaqService } from '../shared/services/faq.service';
import { CategoryEnum } from '../shared/interfaces/ICategory';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  @Input() category?: CategoryEnum;
  @Input() defaultCategoryPreselect?: boolean;
  @Input() count?: number;

  protected isLoading = false;
  protected questions$: Observable<IQuestion[]>;
  protected CategoryEnum = CategoryEnum;
  private company = this.tenantService.getTenantString();

  constructor(
    private tenantService: TenantService,
    private faqService: FaqService
  ) {}

  ngOnInit(): void {
    this.questions$ = this.getQuestions();
  }

  private getQuestions(): Observable<IQuestion[]> {
    return this.faqService.getAllFaqs(this.company).pipe(
      tap(() => {
        this.isLoading = true;
      }),
      map((questions: IQuestion[]) => {
        if (this.category) {
          questions = questions.filter((question: IQuestion) => question.category === this.category);
        }
        if (this.count) {
          questions = questions.slice(0, this.count);
        }
        return questions;
      }),
      finalize(() => {
        this.isLoading = false;
      })
    );
  }
}
