<div class="container">
  <div class="main-tile">
    <h2>{{ 'home.faq.title' | translate }}</h2>
    <button mat-raised-button [routerLink]="'/faq/all'">
      <p class="button-text">{{ 'home.faq.view-all' | translate }}</p>
    </button>
  </div>
  <div class="faq-tiles">
    <app-faq-list
      [showCategorySearch]="true"
      [showTextSearch]="true"
      [defaultCategoryIfLimit]="category"
      [questionLimitIfNoSearch]="count"
      [defaultCategoryPreselect]="defaultCategoryPreselect"
    ></app-faq-list>
  </div>
</div>
