import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { CmsSupportContactsComponent } from './admin/cms-support-contacts/cms-support-contacts.component';
import { ContactComponent } from './contact/contact.component';
import { HelpTopicsComponent } from './help-topics/help-topics.component';
import { HomeComponent } from './home/home.component';
import { HelpTopicsSubpageComponent } from './help-topics/help-topics-subpage/help-topics-subpage.component';
import { FaqAllComponent } from './faq/faq-all/faq-all.component';
import { AdminComponent } from './admin/admin.component';
import { TranslationsComponent } from './admin/translations/translations.component';
import { CmsFaqsComponent } from './admin/cms-faqs/cms-faqs.component';
import { CmsHelpTopicsComponent } from './admin/cms-help-topics/cms-help-topics.component';
import { TenantIndependentComponent } from './tenant-independent/tenant-independent.component';
import { UserManagementComponent } from './admin/user-management/user-management.component';
import { UserRoleEnum } from './shared/enums/UserRoleEnum';
import { RoleGuard } from './auth/services/role-guard.service';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { TenantGuard } from './shared/services/tenant-guard.service';
import { TENANT } from './shared/Tenant.enum';
import { CmsWhatsNewComponent } from './admin/cms-whats-new/cms-whats-new.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AutoLoginPartialRoutesGuard]
  },
  {
    path: 'faq/all',
    component: FaqAllComponent,
    canActivate: [AutoLoginPartialRoutesGuard]
  },
  {
    path: 'tenants',
    component: TenantIndependentComponent,
    canActivate: [AutoLoginPartialRoutesGuard]
  },
  {
    path: 'help-topics',
    component: HelpTopicsComponent,
    canActivate: [AutoLoginPartialRoutesGuard]
  },
  {
    path: 'help-topics/:help-topic',
    component: HelpTopicsSubpageComponent,
    canActivate: [AutoLoginPartialRoutesGuard]
  },
  {
    path: 'contact',
    component: ContactComponent,
    canActivate: [AutoLoginPartialRoutesGuard]
  },
  {
    path: 'logout',
    loadChildren: () => import('./log-out/log-out.module').then(m => m.LogOutModule)
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'admin',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: {
      rolesAllowed: [UserRoleEnum.ADMIN, UserRoleEnum.CONTENT_MANAGER] as UserRoleEnum[]
    },
    children: [
      {
        path: '',
        component: AdminComponent
      },
      {
        path: 'translations',
        component: TranslationsComponent
      },
      {
        path: 'whats-new',
        component: CmsWhatsNewComponent
      },
      {
        path: 'faqs',
        component: CmsFaqsComponent
      },
      {
        path: 'help-topics',
        component: CmsHelpTopicsComponent
      },
      {
        path: 'support-contacts',
        component: CmsSupportContactsComponent
      }
    ]
  },
  {
    path: 'admin/user-management',
    component: UserManagementComponent,
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard, TenantGuard],
    data: {
      rolesAllowed: [UserRoleEnum.ADMIN] as UserRoleEnum[],
      tenantsAllowed: [TENANT.Siemens] as TENANT[]
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
