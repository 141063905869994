import { Pipe, PipeTransform } from '@angular/core';
import { IQuestion } from '../shared/interfaces/IQuestion';
import { CategoryEnum } from '../shared/interfaces/ICategory';

@Pipe({
  name: 'faqCategoryFilter'
})
export class FaqCategoryFilterPipe implements PipeTransform {
  transform(questions: IQuestion[], categories: (string | CategoryEnum)[]): IQuestion[] {
    if (!questions || !categories || !categories.length) {
      return questions;
    }
    return questions.filter(question => categories.includes(question.category));
  }
}
