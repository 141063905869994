import { Component, OnInit, OnDestroy, Input, inject } from '@angular/core';
import { CategoryEnum } from 'src/app/shared/interfaces/ICategory';
import { IQuestion } from 'src/app/shared/interfaces/IQuestion';
import { TenantService } from 'src/app/shared/services/tenant.service';
import { TopicsTileInterface } from 'src/app/shared/interfaces/topics-tile.interface';
import { HelpItemService } from 'src/app/shared/services/help-item.service';
import { filter, mergeMap, Observable, Subject, takeUntil, tap, toArray } from 'rxjs';
import { UserRoleEnum } from 'src/app/shared/enums/UserRoleEnum';
import { Store } from '@ngxs/store';
import { UserState } from 'src/app/auth/store';
import { LoadingService } from '../../shared/services/loading.service';
import { FaqService } from '../../shared/services/faq.service';
import { FaqCategoryFilterPipe } from '../faq-category-filter.pipe';

@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrl: './faq-list.component.scss'
})
export class FaqListComponent implements OnInit, OnDestroy {
  protected userRole$: Observable<UserRoleEnum> = inject(Store).select(UserState.role);

  @Input() showTextSearch: boolean;
  @Input() showCategorySearch: boolean;
  @Input() defaultCategoryIfLimit?: CategoryEnum;
  @Input() defaultCategoryPreselect?: boolean;
  @Input() questionLimitIfNoSearch?: number;

  protected userRoleEnum = UserRoleEnum;
  protected categories: string[];
  protected selectedCategories: CategoryEnum[] = [];
  protected tiles: TopicsTileInterface[] = [];
  protected questions: IQuestion[];
  protected searchValue = '';
  protected searchResult: IQuestion[];
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private tenantService: TenantService,
    private faqService: FaqService,
    private loadingService: LoadingService,
    private helpTopicsService: HelpItemService,
    private faqCategoryFilter: FaqCategoryFilterPipe
  ) {}

  ngOnInit(): void {
    this.getAllFaqs();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  protected startSearch(value: string): void {
    if (!value && !this.selectedCategories.length) {
      this.clearSearch();
      return;
    }
    if (!this.questions) {
      return;
    }

    this.searchValue = value ? value : '';
    this.searchResult = [];
    const filteredQuestions: IQuestion[] = [];
    const searchTerms = value.toLowerCase().split(/\s+/);

    for (const question of this.questions) {
      const questionText = question.question.toLowerCase();
      const answerText = question.answer.toLowerCase();
      const fieldsToSearch = [questionText, answerText];

      const matchesAllTerms = searchTerms.every(term => fieldsToSearch.some(field => field.includes(term)));

      if (matchesAllTerms) {
        filteredQuestions.push(question);
      }
    }

    this.searchResult = this.faqCategoryFilter.transform(filteredQuestions, this.selectedCategories);
  }

  protected clearSearch(): void {
    this.searchValue = '';
    this.searchResult = this.questions;
    this.getCategories();
  }

  protected handleCategoryClicked(category: CategoryEnum, index: number): void {
    this.toggleSelectedCategory(category);
    this.toggleTileSelected(index);
    this.startSearch(this.searchValue);
  }

  private preselectCategoryFilter(): void {
    if (this.defaultCategoryPreselect) {
      this.tiles.find(tile => tile.title === this.defaultCategoryIfLimit).selected = true;
      this.selectedCategories.push(this.defaultCategoryIfLimit);
      this.startSearch(this.searchValue);
    }
  }

  private getAllFaqs(): void {
    this.loadingService.present();
    this.faqService
      .getAllFaqs(this.tenantService.getTenant().toLowerCase())
      .pipe(
        tap(res => {
          this.questions = res;
          this.startSearch(this.searchValue);
          this.getCategories();
          this.getHelpTopicsForCategories();
          this.loadingService.dismiss();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private getHelpTopicsForCategories(): void {
    this.helpTopicsService
      .fetchHelpTiles()
      .pipe(
        mergeMap((tiles: TopicsTileInterface[]) => tiles),
        filter((tile: TopicsTileInterface) => this.categories.includes(tile.title)),
        toArray(),
        tap((filteredTiles: TopicsTileInterface[]) => {
          this.tiles = filteredTiles;
          this.preselectCategoryFilter();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private getCategories(): void {
    if (this.questions !== undefined) {
      this.categories = [];
      for (const value in CategoryEnum) {
        if (this.questions.filter(question => question.category === CategoryEnum[value]).length !== 0) {
          this.categories.push(CategoryEnum[value] as string);
        }
      }
    }
  }

  private toggleTileSelected(index: number): void {
    this.tiles[index].selected = !this.tiles[index].selected;
  }

  private toggleSelectedCategory(category: CategoryEnum): void {
    const index = this.selectedCategories.indexOf(category);
    if (index === -1) {
      this.selectedCategories.push(category);
    } else {
      this.selectedCategories.splice(index, 1);
    }
  }
}
