import { Pipe, PipeTransform } from '@angular/core';
import { CategoryEnum } from 'src/app/shared/interfaces/ICategory';
import { IQuestionExtended } from 'src/app/shared/interfaces/IQuestion';

@Pipe({
  name: 'extendedFaqCategoryFilter'
})
export class ExtendedFaqCategoryFilterPipe implements PipeTransform {
  transform(questions: IQuestionExtended[], categories: (string | CategoryEnum)[]): IQuestionExtended[] {
    if (!questions || !categories || !categories.length) {
      return questions || [];
    }
    return questions.filter(
      question => categories.includes(question.siemens?.category) || categories.includes(question.shs?.category) || categories.includes(question.siemensenergy?.category)
    );
  }
}
