import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DesignService } from './shared/services/design.service';
import { TenantService } from './shared/services/tenant.service';
import { TrackingService } from './shared/services/tracking.service';
import { GetAuthUser } from './auth/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  menuItems = [
    { href: 'home', name: 'Home' },
    { href: 'help-topics', name: 'Help Topics' },
    { href: 'most-relevant', name: 'Most Relevant' },
    { href: 'faq', name: 'FAQs' }
  ];
  favIcon: HTMLLinkElement = document.querySelector('#favicon');
  constructor(
    protected router: Router,
    private designService: DesignService,
    private trackingService: TrackingService,
    private route: ActivatedRoute,
    private tenantService: TenantService,
    private store: Store
  ) {
    this.designService.setColorVariables(this.designService.getColorPalette());
    this.designService.setAssetsVariables(this.designService.getAssets());

    this.router.events.subscribe(res => {
      if (res instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        if (!res.url.startsWith('/auth')) {
          void this.trackingService.addWCSTrackingItem('route', res.url);
        }
      }
    });

    if (tenantService.isTenantSE()) {
      this.favIcon.href = './../assets/favicons/cloud-icon-se.ico';
    } else if (tenantService.isTenantSHS()) {
      this.favIcon.href = './../assets/favicons/cloud-icon-shs.ico';
    } else {
      this.favIcon.href = './../assets/favicons/cloud-icon.ico';
    }
  }

  ngOnInit(): void {
    this.routeToRedirect();
    this.store.dispatch(new GetAuthUser());
  }

  private routeToRedirect(): boolean {
    const url = window.localStorage.getItem('redirectUrl');
    if (url) {
      void this.router.navigateByUrl(url);
      window.localStorage.removeItem('redirectUrl');
    }
    return !!url;
  }
}
