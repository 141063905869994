import { Component, HostListener, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SupportDialogComponent } from '../support-dialog/support-dialog.component';
import { UserState } from '../auth/store';
import { UserRoleEnum } from '../shared/enums/UserRoleEnum';
import { AuthUser } from '../auth/models/auth-user.model';
import { HeaderElementInterface } from './interfaces/header-element.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  protected user$: Observable<AuthUser> = inject(Store).select(UserState.loggedUser);
  protected headerElements: HeaderElementInterface[] = [
    {
      displayName: 'navigation.header-title',
      elemId: 'home'
    },
    {
      displayName: 'navigation.help-items',
      elemId: 'help-topics'
    },
    {
      displayName: 'navigation.faq',
      elemId: 'faq'
    }
  ];
  protected navElemSelected = -1;
  protected sliderWidth = '0px';
  protected sliderOffset = '0px';
  protected readonly yOffset = -68;
  protected userRole?: UserRoleEnum;
  protected UserRoleEnum = UserRoleEnum;

  get isOnHome(): boolean {
    return this.router.url === '/home';
  }

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.initSelectedElemOnNavigation();
      }
    });

    this.store
      .select(UserState.role)
      .pipe(filter(role => role !== undefined))
      .subscribe(role => (this.userRole = role));
  }

  protected onItemClick(event: MouseEvent, elemId: string): void {
    event.preventDefault();

    if (!this.isOnHome) {
      void this.router.navigate(['home']).then(() => {
        setTimeout(() => this.scrollToElem(elemId), 0);
      });
    } else {
      this.scrollToElem(elemId);
    }
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(): void {
    if (!this.isOnHome) {
      return;
    }

    const oldNavElemSelected = this.navElemSelected;
    this.navElemSelected = this.calculateCurrentSelectedElem();

    if (oldNavElemSelected !== this.navElemSelected) {
      this.updateSlider();
    }
  }

  private scrollToElem(elemId: string) {
    const element = document.querySelector(`#${elemId}`);
    const y = element.getBoundingClientRect().top + window.pageYOffset + this.yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  private initSelectedElemOnNavigation(): void {
    if (this.isOnHome) {
      this.navElemSelected = 0;
    } else if (this.router.url.startsWith('/help-topics')) {
      this.navElemSelected = 1;
    } else if (this.router.url.startsWith('/faq')) {
      this.navElemSelected = 3;
    } else {
      this.navElemSelected = -1;
    }
    setTimeout(() => {
      this.updateSlider();
    }, 0);
  }

  private calculateCurrentSelectedElem(): number {
    for (let i = 0; i < this.headerElements.length; i++) {
      const elem = this.headerElements[i];
      const element = document.querySelector(`#${elem.elemId}`);
      const y = element.getBoundingClientRect().top + window.pageYOffset;
      if (y > window.scrollY) {
        return i;
      }
    }
    return this.headerElements.length - 1;
  }

  private updateSlider(): void {
    if (this.navElemSelected === -1) {
      this.sliderWidth = '0px';
      return;
    }

    const elem = this.headerElements[this.navElemSelected];
    const element = document.querySelector(`.nav-${elem.elemId}`);
    const width = element.getBoundingClientRect().width;
    this.sliderWidth = `${width}px`;

    // As the slider's absolute position should be given relative to the navbar, but the x is relative to the window
    // We need to do x - parentLeft to find the position relative to the navbar
    const x = element.getBoundingClientRect().left;
    const parentLeft = element.parentElement.getBoundingClientRect().left;
    this.sliderOffset = `${x - parentLeft}px`;
  }

  openSupportDialog(): void {
    this.dialog.open(SupportDialogComponent, {
      minWidth: '600px',
      width: '50vw',
      maxHeight: '90vh'
    });
  }
}
