import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IQuestion, IQuestionExtended } from '../interfaces/IQuestion';
import { environment } from '../../../environments/environment';
import { TENANT } from '../Tenant.enum';

@Injectable()
export class FaqService {
  private allQuestions?: IQuestion[];
  constructor(public http: HttpClient) {}

  getAllFaqs(company: string): Observable<IQuestion[]> {
    if (this.allQuestions) {
      return of(this.allQuestions);
    }

    const URL = `${environment.api.backend}faq?tenant=${company}`;
    return this.http.get<IQuestion[]>(URL).pipe(tap(questions => (this.allQuestions = questions)));
  }

  getAllForCMS(): Observable<IQuestionExtended[]> {
    const URL = `${environment.api.backend}faq/cms`;
    return this.http.get<IQuestionExtended[]>(URL);
  }

  saveNewFAQ(tenant: TENANT, question: IQuestion): Promise<any> {
    const URL = `${environment.api.backend}faq?tenant=${tenant}&id=${question.id}&language=en`;
    return this.http.post(URL, question).toPromise();
  }

  updateFAQ(tenant: TENANT, question: IQuestion): Promise<any> {
    const URL = `${environment.api.backend}faq?tenant=${tenant}&id=${question.id}&language=en`;
    return this.http.put(URL, question).toPromise();
  }
}
