import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CategoryEnum } from 'src/app/shared/interfaces/ICategory';
import { FaqService } from 'src/app/shared/services/faq.service';
import { HelpItemService } from 'src/app/shared/services/help-item.service';
import { mergeMap, toArray, tap, takeUntil, Subject } from 'rxjs';
import { TopicsTileInterface } from 'src/app/shared/interfaces/topics-tile.interface';
import { LoadingService } from '../../shared/services/loading.service';
import { CsvHelperService } from './../../shared/services/csv-helper.service';
import { AddFaqComponent } from './add-faq/add-faq.component';
import { IQuestionExtended } from './../../shared/interfaces/IQuestion';
import { CmsFaqOperations } from './cms-faqs.operations';
import { ExtendedFaqCategoryFilterPipe } from './extended-faq-filter-pipe.pipe';

@Component({
  selector: 'app-cms-faqs',
  templateUrl: './cms-faqs.component.html',
  styleUrls: ['./cms-faqs.component.scss']
})
export class CmsFaqsComponent implements OnInit, OnDestroy {
  protected CmsFaqOperations = CmsFaqOperations;
  protected categories: string[] = Object.values(CategoryEnum);
  protected questions: IQuestionExtended[];
  protected searchValue = '';
  protected searchResult: IQuestionExtended[];
  protected tiles: TopicsTileInterface[] = [];
  protected selectedCategories: string[] = [];
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialog: MatDialog,
    private faqService: FaqService,
    private loadingService: LoadingService,
    private csvHelper: CsvHelperService,
    private helpTopicsService: HelpItemService,
    private extendedFaqCategoryFilterPipe: ExtendedFaqCategoryFilterPipe
  ) {}

  ngOnInit(): void {
    this.getAllFaqsForCMS();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  protected openNewFaqDialogue(): void {
    this.dialog.open(AddFaqComponent, {
      minWidth: '600px',
      width: '70vw',
      maxHeight: '95vh'
    });
  }

  protected startSearch(value: string): void {
    if (!value && !this.selectedCategories.length) {
      this.clearSearch();
      return;
    }
    if (!this.questions) {
      return;
    }
    this.searchValue = value ? value : '';
    this.searchResult = [];
    const filteredQuestions: IQuestionExtended[] = [];

    const searchTerms = value.toLowerCase().split(/\s+/);

    for (const question of this.questions) {
      const fieldsToSearch = [
        question.siemens?.answer,
        question.siemens?.question,
        question.shs?.answer,
        question.shs?.question,
        question.siemensenergy?.answer,
        question.siemensenergy?.question
      ]
        .filter(field => field !== undefined)
        .map(field => field.toLowerCase());

      const matchesAllTerms = searchTerms.every(term => fieldsToSearch.some(field => field.includes(term)));

      if (matchesAllTerms) {
        filteredQuestions.push(question);
      }
    }

    this.searchResult = this.extendedFaqCategoryFilterPipe.transform(filteredQuestions, this.selectedCategories);
  }

  protected handleCategoryClicked(category: CategoryEnum, index: number): void {
    this.toggleSelectedCategory(category);
    this.toggleTileSelected(index);
    this.startSearch(this.searchValue);
  }

  protected clearSearch(): void {
    this.searchValue = '';
    this.searchResult = this.questions;
  }

  protected exportAsCSV(questions: IQuestionExtended[]): void {
    const downloadData = CmsFaqOperations.createObjectsForCsvDownload(questions);
    this.csvHelper.downloadRecordsAsCsv(downloadData.shsData);
    this.csvHelper.downloadRecordsAsCsv(downloadData.siemensData);
    this.csvHelper.downloadRecordsAsCsv(downloadData.siemensenergyData);
  }

  private getAllFaqsForCMS(): void {
    this.loadingService.present();
    this.faqService
      .getAllForCMS()
      .pipe(
        tap(res => {
          this.questions = res;
          this.startSearch(this.searchValue);
          this.getHelpTopicsForCategories();
          this.loadingService.dismiss();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private getHelpTopicsForCategories(): void {
    this.helpTopicsService
      .fetchHelpTiles()
      .pipe(
        mergeMap((tiles: TopicsTileInterface[]) => tiles),
        toArray(),
        tap((tiles: TopicsTileInterface[]) => {
          this.tiles = tiles;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private toggleTileSelected(index: number): void {
    this.tiles[index].selected = !this.tiles[index].selected;
  }

  private toggleSelectedCategory(category: CategoryEnum): void {
    const index = this.selectedCategories.indexOf(category);
    if (index === -1) {
      this.selectedCategories.push(category);
    } else {
      this.selectedCategories.splice(index, 1);
    }
  }
}
