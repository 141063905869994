<div class="faq-list-actions">
  @if ((userRole$ | async) === userRoleEnum.ADMIN) {
    <button class="add-faq-item" mat-raised-button [routerLink]="'/admin/faqs'">
      {{ 'admin-page.faq.buttons.go-to-admin-faq' | translate }}
    </button>
  }
</div>
<!-- SEARCH -->
<app-faq-search
  [showTextSearch]="showTextSearch"
  [showCategorySearch]="showCategorySearch"
  [categoryTiles]="tiles"
  [amountOfSearchHits]="this.searchValue || this.selectedCategories?.length ? (searchResult ? searchResult.length : 0) : undefined"
  (categoryClicked)="handleCategoryClicked($event.category, $event.categoryIndex)"
  (searchInput)="startSearch($event)"
  (searchCleared)="clearSearch()"
></app-faq-search>
<!-- FAQS -->
@if (defaultCategoryIfLimit && !searchValue && !selectedCategories.length) {
  <h2>
    {{ defaultCategoryIfLimit }}
  </h2>
  @for (item of questions | faqCategoryFilter: [defaultCategoryIfLimit] | slice: 0 : (questionLimitIfNoSearch ? questionLimitIfNoSearch : undefined); track item) {
    <app-question [question]="item" />
  }
} @else {
  @for (category of categories; track category) {
    @if (searchResult | faqCategoryFilter: [category]; as categoryQuestions) {
      @if (categoryQuestions.length) {
        <div class="category-container">
          <h2>
            {{ category }}
          </h2>
          <div class="faq-tiles">
            @for (item of categoryQuestions; track item) {
              <app-question [question]="item" />
            }
          </div>
        </div>
      }
    }
  }
}
